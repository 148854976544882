<template>
  <div class="integral-datail-container">
    <div class="integral-datail-tip">
      <p class="title1">Meta彼岸积分规则</p>
      <p class="title2">一、Meta彼岸积分</p>
      <p class="content">可通过签到、邀请、购买藏品和其它积分奖励活动等方式来获取Meta彼岸积分（以下简称“积分”），积分可以在Meta彼岸兑换各类权益、购买藏品时可抵现、参与各种积分活动等，具体以积分活动页面展示为准。</p>
      <p class="title2">二、积分发放规则</p>
      <p class="content">用户完成积分奖励活动后，及时发放。用户可前往“我的--积分”，查看已获得的积分以及有效期。如遇系统繁忙、交易异常等情况，积分将延迟发放。</p>
      <p class="title2">三、积分获取方式</p>
      <p class="title3">1、购买数字藏品</p>
      <p class="content">购买数字藏品（包括不限于普通数字藏品、盲盒等），交易成功后可获得相应积分。是否返积分返多少积分，以购买页信息提示为准。</p>
      <p class="title3">2、签到</p>
      <p class="content">用户可在“积分”页面进行签到，每日签到获得积分数量不同，具体以签到页面为准，连续签到可获得每日签到积分。</p>
      <p class="title3">3、邀请好友</p>
      <p class="content">用户邀请好友成功注册Meta彼岸账户后，获取对应数量的积分，具体以“积分记录”页面为准。</p>
      <p class="title2">四、积分消耗方式</p>

      <p class="title3">1、购买抵扣</p>
      <p class="content">用户购买数字藏品时可选择积分抵现，用户可在“支付订单”页面选择使用积分抵扣，抵扣后，用户可在“积分记录”页面查看抵扣记录。</p>
      <p class="title2">五、积分有效期</p>
      <p class="content">用户获得的积分有效期为自获得当日起1年内有效，有效期内未使用的积分到期将自动作废。积分消耗时优先消耗先获取的积分。</p>
      <p class="title3">温馨提示：</p>
      <p class="content">1、用户日常使用的积分，将优先使用即将过期的积分</p>
      <p class="content">2、用户可在“积分记录”页面查看即将过期的积分</p>
      <p class="content">3、已使用的积分若发生退换，积分有效期不变，仍以该笔积分原获取时间计算有效期</p>
      <p class="title2">六、其他说明</p>
      <p class="content">1、如用户存在违规刷积分行为（包括但不限于虚假交易、恶意套现等），我们有权取消用户获得积分的资格，已领取的积分将被扣回</p>
      <p class="content">2、为了进一步改善用户体验，我们将不定时更新积分服务的内容，积分规则也可能随之更新，我们会以公告、客户端通知、短信或弹窗等方式（统称“通知”）就更新内容向您进行告知，更新内容将在前述通知指定日期开始生效。若您不同意更新后的内容，您有权停止使用相关服务；双方协商一致的，也可另行变更相关服务对应内容。您也可以随时在本页面查阅积分规则的最新版本。</p>
      <p class="content">3、账户成功注销后，账户中的积分将被清零，请谨慎操作。</p>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {

    }
  },
  mounted () {
    this.$store.commit('HIDE_APPLOADING')
  },
}
</script>
<style lang="less" scoped>
.integral-datail-container {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-image: url('../../assets/icon/giftLogin/bg.png');
  background-size: cover;
  .integral-datail-tip {
    width: 90vw;
    margin-left: 5vw;
    background: #262728;
    margin-top: 4.875rem;
    height: calc(100vh - 6.875rem);
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 0;
    }
    .title1 {
      width: 90%;
      margin-left: 5%;
      font-size: 15px;
      font-family: Adobe Heiti Std;
      font-weight: normal;
      color: #f0f0f0;
      margin-bottom: 15px;
      margin-top: 15px;
    }
    .title2 {
      width: 86%;
      margin-left: 7%;
      font-size: 13px;
      font-family: Adobe Heiti Std;
      font-weight: normal;
      color: #f0f0f0;
      margin-bottom: 15px;
    }
    .title3 {
      width: 86%;
      margin-left: 7%;
      font-size: 12px;
      font-family: Adobe Heiti Std;
      font-weight: normal;
      color: #f0f0f0;
    }
    .content {
      width: 86%;
      margin-left: 7%;
      font-size: 12px;
      font-family: Adobe Heiti Std;
      font-weight: normal;
      color: #a8a8a8;
      margin-bottom: 15px;
    }
  }
}
</style>